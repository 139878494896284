// Here you can add other styles
.custom-card {
    margin-bottom: 30px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    letter-spacing: 0.5px;
    border-radius: 15px;
    -webkit-box-shadow: 0 0 20px rgba(8, 21, 66, 0.5);
    box-shadow: 0 0 20px rgba(8, 21, 66, 0.08);
    padding: 30px;
    background-color: white;

    .user-profile-div {
        padding: 0 0 30px;
    }

    .heading {
        border-bottom: 1px solid #eee;
        padding-bottom: 28px;
        margin-bottom: 30px;
        font-size: 24px;
    }

    .job-details {
        h3 {
            margin-bottom: 15px;
        }

        p {
            font-size: 16px !important;
        }

        .first-flex {
            width: 18% !important;
        }
    }
}

.custom-card {
    margin-bottom: 30px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    letter-spacing: 0.5px;
    border-radius: 15px;
    -webkit-box-shadow: 0 0 20px rgba(8, 21, 66, 0.5);
    box-shadow: 0 0 20px rgba(8, 21, 66, 0.08);
    padding: 30px;
    background-color: white;

    .user-profile-div {
        padding: 0 0 30px;
    }

    .heading {
        border-bottom: 1px solid #eee;
        padding-bottom: 28px;
        margin-bottom: 30px;
        font-size: 24px;
    }

    .job-details {
        h3 {
            margin-bottom: 15px;
        }

        p {
            font-size: 16px !important;
        }

        .first-flex {
            width: 18% !important;
        }
    }
}


.pagination button {
    padding: 8px 16px;
    margin: 0 4px;
    cursor: pointer;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #f1f1f1;
}

.detail-box-div {
    border: 1px solid #eee;
    border-radius: 8px;
}

.detail-box-div a {
    width: 10rem;
    text-align: center;
    text-decoration: none;
}

.detail-box-div a p {
    margin-bottom: .5rem;
    color: #333;
    font-size: 20px;
    font-weight: 300;
}

.detail-box-div a span {
    color: #333;
    font-size: 24px;
    font-weight: 500;
}

.detail-box-div a:hover p,
.detail-box-div a:hover span{
    color: #2e2ea3;
}

.custom-tabs {
    border-bottom: 1px solid lightgray;
}

.custom-tabs a {
    text-decoration: none;
    padding: 0.6rem 1rem;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 15px;
    border-bottom-width: 2px;
    border-bottom-style: solid;
}

.image-preview{
    width: 100px !important;
}